.bg {
  @apply h-screen w-screen bg-center bg-no-repeat bg-cover relative min-h-[600px];
  background-color: #030100;
  background-image: url("./images/DSC0610.JPG");
}

.bg-overlay {
  @apply absolute w-full min-h-full;
  background: rgba(0, 0, 0, 0.4);
}


.button {
  @apply text-3xl font-bold text-white  px-5 py-2 tracking-widest leading-normal mt-8;
  border: 3px solid #fff;
  background-image: -webkit-linear-gradient(30deg, transparent 50%,#fff  50%);
  background-image: linear-gradient(30deg, transparent 50%,#fff  50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.button:hover {
  background-position: 100%;
  @apply text-black;
}